// extracted by mini-css-extract-plugin
export var a = "index-module--a--6f42b";
export var aboutOutline = "index-module--aboutOutline--a1eb7";
export var backgroundImg = "index-module--backgroundImg--71aa8";
export var contactOutline = "index-module--contactOutline--fbde8";
export var footer = "index-module--footer--d9f6d";
export var gridContainer = "index-module--gridContainer--f9538";
export var h_link = "index-module--h_link--e6eae";
export var hamburgerMenu = "index-module--hamburgerMenu--04108";
export var header_1 = "index-module--header_1--27b0f";
export var layoutContainer = "index-module--layoutContainer--5b2f3";
export var li = "index-module--li--b898a";
export var link = "index-module--link--68ddd";
export var nav = "index-module--nav--ef89a";
export var projectsOutline = "index-module--projectsOutline--504f1";
export var subHeading = "index-module--subHeading--c85c1";
export var ul = "index-module--ul--22082";